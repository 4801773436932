import React from 'react';
import laurel1 from '../img/laurel1.png';
import laurel2 from '../img/laurel2.png';
import laurel3 from '../img/laurel3.png';
import laurel4 from '../img/laurel4.png';
import filmevent_logo from '../img/filmevent_logo_yellow_beige_white.png';

const TopSec = (props) => {
  const { isMobile } = props;
  return (
    <div
      className={`d-flex justify-content-${isMobile ? 'center' : 'between'}`}
      style={{ marginTop: '40px', paddingRight: '133px', paddingLeft: '133px' }}
    >
      {isMobile ? null : (
        <>
          <img src={laurel4} alt='laurel4' style={{ maxWidth: '233px' }} />
          <img src={laurel3} alt='laurel3' style={{ maxWidth: '188px' }} />
        </>
      )}
      <div>
        <img
          className={`align-self-center mt-4 ${isMobile ? '' : 'ml-3'}`}
          src={filmevent_logo}
          alt='filmevent_logo'
        />
        <div className='col-md-12 d-flex justify-content-center mt-1 presents'>
          {' '}
          PRESENTS
        </div>
      </div>
      {isMobile ? null : (
        <>
          <img src={laurel2} alt='laurel2' style={{ maxWidth: '188px' }} />
          <img src={laurel1} alt='laurel1' style={{ maxWidth: '233px' }} />
        </>
      )}
    </div>
  );
};

export default TopSec;
