import React, { useState } from 'react';
import playBlack from '../img/play_black.png';
import downloadImg from '../img/download.png';
import { checkVimeoData, videoMetaFromName } from '../utils/helpres';
const VimeoCard = (props) => {
  const { vFetch, setOpenVideoModal, setModalIframe, isMobile } = props;
  const videoMeta = videoMetaFromName(vFetch.name);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);
  const download = (url, name) => {
    if (!url) {
      throw new Error('Resource URL not provided! You need to provide one');
    }
    setFetching(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        setFetching(false);
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        a.style = 'display: none';

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch(() => setError(true));
  };
  return checkVimeoData(vFetch) ? (
    <>
      <div>
        <div className='card pointer' style={{ width: '18rem', margin: '20px' }}>
          <img src={vFetch.pictures?.base_link} alt={vFetch.pictures?.base_link} />

          <div className='card-body'>
            <h5 className='card-title'>{videoMeta.videoName}</h5>
            <div className='d-flex justify-content-center'>
              <div className='col-md-8 card-text'>
                {videoMeta.date} | {videoMeta.genre} | {videoMeta.author}
              </div>
              <button
                onClick={() => {
                  // if (isMobile) {
                  //   window.open(vFetch.player_embed_url);
                  // } else {
                  setOpenVideoModal('modal display-block');
                  setModalIframe(vFetch);
                  // }
                }}
                className='col-md-2 btn btn-sm btn-outline-gray border-0'
              >
                <img src={playBlack} alt='play' />
              </button>
              <button
                className='col-md-2 btn btn-sm btn-outline-gray border-0'
                onClick={() => download(vFetch.files[0].link, vFetch.name)}
              >
                <img width={'30px'} src={downloadImg} alt='download' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default VimeoCard;
