export const checkVimeoData = (vimeo) => {
  if (!vimeo) {
    console.log('No Vimeo');
    return false;
  }
  if (
    !vimeo.pictures ||
    !vimeo.pictures.sizes ||
    !vimeo.pictures.sizes[3] ||
    !vimeo.pictures.sizes[3].link ||
    !vimeo.player_embed_url ||
    !vimeo.link
  ) {
    console.log('Wrong Vimeo Data');
    return false;
  }
  return true;
};

export const videoMetaFromName = (name) => {
  const filterFormat = name.replace('.mp4', '');
  const properties = filterFormat.split('_');
  const keys = ['date', 'company', 'genre', 'videoName', 'author'];
  let result = {};
  properties.map((prop, i) => (result[keys[i]] = prop));
  return result;
};
