import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Vimeo from '@u-wave/react-vimeo';
import ReactPlayer from 'react-player';
import { fetchData, fetchVideo } from '../services/fetchData';
import TopSec from '../layout/TopSec';
import VimeoCard from '../layout/VimeoCard';
import instagramIcon from '../img/instagramWhite.png';
import Spinner from './Spinner';
const Index = () => {
  const { project_id } = useParams();
  const [companyName, setCompanyName] = useState([]);
  const [password, setPassword] = useState(undefined);
  const [vimeos, setVimeos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState('modal display-none');
  const [modalIframe, setModalIframe] = useState(undefined);

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 1313;

  ///////////////////////////////////////////////////
  const vimeoPlayer = <Vimeo video={modalIframe?.link} autoplay width={isMobile ? width : width - 350} />;
  const reactPlayer = (
    <ReactPlayer
      url={modalIframe?.play?.source?.link}
      playing={true}
      controls={true}
      loop={true}
      muted={true}
      playsInline={true}
      onReady={true}
      width={`${width - 90}px`}
      height={`${height - 90}px`}
    />
  );
  const Modal = () => {
    return modalIframe ? (
      <div className={openVideoModal}>
        <section className='modal-main'>
          <div className='d-flex justify-content-end'>
            <button
              className='btn btn-dark border-0 ml-auto'
              onClick={() => {
                setOpenVideoModal('modal display-none');
                setModalIframe(undefined);
              }}
            >
              X
            </button>
          </div>
          <div className='d-flex justify-content-end'>{vimeoPlayer}</div>
        </section>
      </div>
    ) : null;
  };

  const askForPassword = (
    <div className='mt-5'>
      <TopSec isMobile={isMobile} />
      <div className='d-flex justify-content-center' style={{ marginTop: '40px' }}>
        <div>
          <h1>Please Enter Password</h1>{' '}
        </div>
      </div>
      <div>
        <div className='d-flex justify-content-center'>
          <div className='col-md-4d-flex justify-content-center mt-3'>
            <input
              className='form-control'
              placeholder='password...'
              type='password'
              style={{ textAlign: 'center' }}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className='row d-flex justify-content-center mt-3'>
        <div className='col-md-6 d-flex justify-content-center mt-3'>
          <button
            className='btn btn-outline-light'
            onClick={async () => {
              setLoading(true);
              const data = await fetchData(project_id, password);
              setCompanyName(data.companyName);
              let vimeosLoad = [];

              for (let i = 0; i < data.vimeoToFetch.length; i++) {
                const vimeoFetch = await fetchVideo(project_id, password, data.vimeoToFetch[i]);
                vimeosLoad = [...vimeosLoad, ...vimeoFetch];
              }

              setVimeos(vimeosLoad);
            }}
          >
            Get Videos
          </button>
        </div>
      </div>

      {loading && vimeos.length === 0 ? (
        <div className='row d-flex justify-content-center mt-3'>
          <Spinner />
        </div>
      ) : null}
    </div>
  );

  const showVideos = (
    <div>
      <TopSec isMobile={isMobile} />
      <div className='d-flex justify-content-center' style={{ marginTop: '20px' }}>
        <div className='companyName'>{companyName}</div>
      </div>

      <Modal />
      <div className='d-flex justify-content-center flex-wrap mb-4 filmFestival' style={{ marginTop: '20px' }}>
        FILMFESTIVAL 2022
      </div>
      <div className='d-flex justify-content-center flex-wrap mb-4' style={{ marginTop: '20px' }}>
        <button
          className='followInstagramButton btn btn-primary'
          onClick={() => window.open('https://instagram.com/filmevent.ch', '_blank')}
        >
          <img
            style={{
              width: '35px',
              height: '35px',
              marginRight: '10px',
            }}
            src={instagramIcon}
            alt='instagram'
          />{' '}
          <b>FOLLOW US ON INSTAGRAM</b>
        </button>
      </div>
      <div className='d-flex justify-content-center flex-wrap mb-4' style={{ marginTop: '20px' }}>
        {vimeos.map((vFetch) => (
          <VimeoCard
            key={vFetch.created_time}
            vFetch={vFetch}
            setOpenVideoModal={setOpenVideoModal}
            setModalIframe={setModalIframe}
            isMobile={isMobile}
          />
        ))}
      </div>
    </div>
  );

  return vimeos.length ? showVideos : askForPassword;
};

export default Index;
