import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Index from './layout/Index';
import NotFound from './layout/NotFound';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/:project_id' element={<Index />} />

        <Route element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
