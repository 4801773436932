import axios from 'axios';

const baseRoute = process.env.REACT_APP_BACKEND_URI;
const mainServerToken = process.env.REACT_APP_MAIN_SERVER_TOKEN;

export const fetchData = async (projectId, password) => {
  try {
    const payload = {
      mainServerToken,
      projectId,
      password,
    };
    const result = await axios.post(`${baseRoute}/fetchAllData`, payload);
    return result.data;
  } catch (error) {
    return false;
  }
};

export const fetchVideo = async (projectId, password, videoName) => {
  try {
    const payload = {
      mainServerToken,
      projectId,
      password,
      videoName,
    };
    const result = await axios.post(`${baseRoute}/getVimeos`, payload);
    return result.data;
  } catch (error) {
    return false;
  }
};
